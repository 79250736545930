<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Data Penilaian Resiko'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12" class="mb-2">
              <b-button variant="outline-dark" @click="$router.push('/equipment_kritis/add')">
                <span class="svg-icon svg-icon-sm svg-icon-dark">
                  <i class="flaticon2-plus"></i>
                </span>
                Create
              </b-button>
            </b-col>
            <b-col md="12">
              <b-table
                head-variant="dark"
                bordered
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                stacked="lg"
                show-empty
                responsive
                hover
                :no-local-sorting="true"
                @sort-changed="sortTable"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                @filtered="onFiltered"
                @row-clicked="info"
                :busy="isBusy"
              >
                <template #thead-top="data">
                  <b-tr>
                    <b-th>
                      <date-picker
                        v-model="search.tanggal"
                        type="date"
                        value-type="YYYY-MM-DD"
                        range
                        input-class="form-control form-control-sm"
                        placeholder="Pilih tanggal dalam range"
                        @change="loadData"
                        @clear="ClearDate"
                      ></date-picker>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-1"
                        size="sm"
                        @input="loadData"
                        v-model="search.nama_equipment"
                        type="search"
                        placeholder="Cari Nama Equipment..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-3"
                        size="sm"
                        @input="loadData"
                        v-model="search.nama_plant"
                        type="search"
                        placeholder="Cari Plant..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-4"
                        size="sm"
                        @input="loadData"
                        v-model="search.nama_pabrik"
                        type="search"
                        placeholder="Cari Pabrik..."
                      ></b-form-input>
                    </b-th>
                    <b-th colspan="4"></b-th>
                  </b-tr>
                </template>

                <template #cell(nama_equipment)="data">
                  {{ `${data.item.nama_equipment} (${data.item.nomor_equipment})` }}
                </template>

                <template #cell(resiko)="data">
                  {{ data.item.konsekuensi * data.item.probability }}
                </template>

                <template #cell(tanggal)="data">
                  {{ moment(data.item.tanggal) }}
                </template>

                <template #cell(actions)="data">
                  <b-dropdown text=". . ." variant="danger" size="sm" no-caret>
                    <b-dropdown-item-button @click="info(data.item, $event.target)">
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-success">
                          <i class="flaticon-eye text-success"></i>
                        </span>
                      </div>
                      View
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      @click="
                        $router.push('/equipment_kritis/edit/' + data.item.id_equipment_kritis)
                      "
                    >
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-primary">
                          <i class="flaticon2-pen text-primary"></i>
                        </span>
                      </div>
                      Edit
                    </b-dropdown-item-button>
                    <b-dropdown-item-button @click="toDelete(data)">
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-danger">
                          <i class="flaticon2-trash text-danger"></i>
                        </span>
                      </div>
                      Delete
                    </b-dropdown-item-button>
                  </b-dropdown>
                </template>

                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
            <b-col md="5">
              <b-pagination
                v-model="currentPage"
                @input="paginate"
                :total-rows="totalRows"
                :per-page="10"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
            <b-col md="5">
              <h4 class="float-right">
                Showing
                <span v-if="totalPerPage">1-{{ totalPerPage }}</span>
                <span v-else>0</span>

                of {{ totalRows }}
              </h4>
            </b-col>
          </b-row>
          <b-modal id="info-modal" size="lg" title="Detail" ok-only @hide="resetInfoModal">
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Nama Equipment :</b></b-col>
              <b-col>{{ modalInfo.nama_equipment }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Tanggal :</b></b-col>
              <b-col>{{ modalInfo.tanggal }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Probability :</b></b-col>
              <b-col>{{ modalInfo.probability }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Konsekuensi :</b></b-col>
              <b-col>{{ modalInfo.konsekuensi }}</b-col>
            </b-row>
          </b-modal>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

import * as moment from 'moment'
import 'moment/locale/id'

export default {
  data() {
    return {
      sortBy: 'tanggal',
      sortDesc: true,
      sort_label: 'DESC',
      items: [],
      fields: [
        { key: 'tanggal', label: 'Tanggal', sortable: true },
        { key: 'nama_equipment', label: 'Equipment', sortable: true },
        { key: 'nama_plant', label: 'Plant', sortable: true },
        { key: 'nama_pabrik', label: 'Pabrik', sortable: true },
        { key: 'konsekuensi', label: 'Konsekuensi', sortable: true },
        { key: 'probability', label: 'Probability', sortable: true },
        { key: 'resiko', label: 'Resiko', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      search: {
        nama_equipment: '',
        nama_pabrik: '',
        nama_plant: '',
        tanggal: ['', ''],
      },
      modalInfo: {
        nama_equipment: null,
        tanggal: null,
        probability: null,
        konsekuensi: null,
      },
      totalRows: 0,
      totalPerPage: 0,
      currentPage: 1,

      isBusy: false,
      dialog: false,

      urlStorage: '',
    }
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Penilaian Resiko', route: '' },
      { title: 'Penilaian Resiko' },
    ])
  },
  created() {
    var self = this
    self.isBusy = true
    self.urlStorage = ApiService.urlStorage()
    ApiService.get(
      '/master/equipment_kritis?page=' +
        this.currentPage +
        '&nama_equipment=' +
        this.search.nama_equipment +
        '&nama_pabrik=' +
        this.search.nama_pabrik +
        '&nama_plant=' +
        this.search.nama_plant +
        '&tanggal_start=' +
        this.search.tanggal[0] +
        '&tanggal_end=' +
        this.search.tanggal[1] +
        '&sortBy=' +
        this.sortBy +
        '&sortDesc=' +
        this.sort_label
    )
      .then(({ data }) => {
        if (data.status == 'ok') {
          self.items = data.data.data
          self.totalRows = data.data.total
          self.totalPerPage = data.data.data.length
          self.isBusy = false
        }
      })
      .catch(response => {
        console.log(response)
      })
  },
  methods: {
    moment: function(date) {
      moment.locale('id')
      return moment(date).format('DD/MM/YYYY')
    },
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.loadData()
    },
    ClearDate() {
      this.search.tanggal[0] = ''
      this.search.tanggal[1] = ''
    },
    info(item, button) {
      this.modalInfo.nama_equipment = item.nama_equipment
      this.modalInfo.tanggal = item.tanggal
      this.modalInfo.probability = item.probability
      this.modalInfo.konsekuensi = item.konsekuensi
      this.$root.$emit('bv::show::modal', 'info-modal', button)
    },
    resetInfoModal() {
      this.modalInfo.nama_equipment = null
      this.modalInfo.tanggal = null
      this.modalInfo.probability = null
      this.modalInfo.konsekuensi = null
    },
    paginate() {
      this.loadData()
    },
    sortTable() {
      this.sort_label = 'ASC'
      if (this.sortDesc) {
        this.sort_label = 'DESC'
      }
      this.sortDesc = !this.sortDesc
      this.loadData()
    },
    async loadData() {
      var self = this
      self.isBusy = true

      await this.timeout(100)
      ApiService.get(
        '/master/equipment_kritis?page=' +
          this.currentPage +
          '&nama_equipment=' +
          this.search.nama_equipment +
          '&nama_pabrik=' +
          this.search.nama_pabrik +
          '&nama_plant=' +
          this.search.nama_plant +
          '&tanggal_start=' +
          this.search.tanggal[0] +
          '&tanggal_end=' +
          this.search.tanggal[1] +
          '&sortBy=' +
          this.sortBy +
          '&sortDesc=' +
          this.sort_label
      )
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.items = data.data.data
            self.totalPerPage = data.data.data.length
            self.isBusy = false
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    toDelete(row) {
      var id = row.item.id_equipment_kritis
      let self = this
      this.$confirm({
        auth: false,
        message: 'Anda yakin akan menghapus data tersebut?',
        button: {
          yes: 'Ya',
          no: 'Batal',
        },
        callback: confirm => {
          if (confirm) {
            ApiService.get('/master/equipment_kritis/delete/' + id).then(({ data }) => {
              if (data.status == 'ok') {
                self.isBusy = true
                var interval1 = null
                var i = 0
                self.loadData()
                interval1 = setInterval(function() {
                  if (i == 0) {
                    self.totalRows--
                    self.makeToast('warning', 'Data berhasil dihapus')
                    self.isBusy = false
                  } else {
                    clearInterval(interval1)
                  }
                  i++
                }, 1000)
              }
            })
          }
        },
      })
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
}
</script>
<style>
.mx-datepicker-range {
  width: 200px;
}
</style>
